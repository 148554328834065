import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMarkInvalid]',
})
export class MarkInvalidDirective {
  @Input() appendError = true;
  hasError = false;
  errors = {
    required: 'Field is required',
    email: 'Email is invalid',
    emailTaken: 'Email is already taken',
    ssnTaken: 'SSN is already taken',
    incorrectEmail: 'The email must be a valid email address',
  };
  currentError = '';

  constructor(
    @Self() private ngControl: NgControl,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  @HostBinding('style.border-color')
  get borderColor(): string {
    if (this.ngControl.control) {
      if (this.ngControl.control.invalid && this.ngControl.control.touched) {
        this.appendErrorSpan();
        return '#dc7070';
      } else {
        this.removeErrorSpan();
        return '';
      }
    } else {
      return '';
    }
  }

  @HostListener('focus')
  onBlur(): void {
    if (this.ngControl.control) {
      this.ngControl.control.markAsTouched();
    }
  }

  private appendErrorSpan(): void {
    const error: string = Object.keys(this.ngControl.control.errors || {}).slice(-1)[0];
    const newError = this.calculateError(error);
    if (newError === this.currentError) return; // skip if error has not changed
    this.currentError = newError;
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    if (parent) {
      this.renderer.setStyle(parent, 'position', 'relative');
    }

    if (this.appendError) {
      if (this.hasError) {
        const oldChild = parent.querySelector('span.err-msg');
        this.renderer.removeChild(parent, oldChild);
      }
      const errorSpan = this.renderer.createElement('span');
      this.renderer.addClass(errorSpan, 'err-msg');
      this.renderer.setProperty(errorSpan, 'innerText', this.currentError);
      this.renderer.appendChild(parent, errorSpan);
    }
    this.renderer.addClass(this.elRef.nativeElement, 'err');
    this.hasError = true;
  }

  private getError(): any {
    if (typeof this.ngControl.control.errors === 'string') {
      return this.ngControl.control.errors;
    }
  }

  private calculateError(error): string {
    return this.errors[error] || this.ngControl.control.errors.message || this.getError() || 'Field is invalid';
  }

  private removeErrorSpan(): void {
    this.hasError = false;
    this.currentError = '';
    this.renderer.removeClass(this.elRef.nativeElement, 'err');
    if (this.appendError) {
      const parent = this.renderer.parentNode(this.elRef.nativeElement);
      const oldChild = parent.querySelector('span.err-msg');
      if (oldChild) {
        if (oldChild.parentNode === parent) {
          this.renderer.removeChild(parent, oldChild);
        }
      }
    }
  }
}
